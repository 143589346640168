import * as React from "react"
import "../styles/index.css"
import Layout from "../components/layout"


const IndexPage = () => {
    return (
        <Layout pageTitle="Peter Mason | Data Scientist and Writer" opacity="low">
            <div class="center-vertical">
                <h1 class="self-name">Hi, I'm <span>Peter Mason</span></h1>
                <h2 class="self-titles">Data Scientist &nbsp; <span>|</span> &nbsp; Writer &nbsp; <span>|</span> &nbsp; Tennis Player </h2>
                <div class="self-description">
                    I'm a Seattle-based data scientist currently doing ______ for ________.  I enjoy working on assorted side projects, writing about a mix of technical and semi-philosophical topics, and playing tennis whenever I get the chance.
                </div>
            </div>
        </Layout >
    )
}

export default IndexPage
